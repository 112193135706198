import React from 'react'
import Base from '../layouts/base'
import Hero from '../components/hero/index'
import Content from '../layouts/content'
import TopPosts from '../components/sidenav/top-posts'
import RecentPosts from '../components/sidenav/recent-posts'
import TagList from '../components/sidenav/tag-list'
import Sidenav from '../components/sidenav'
import qs from 'qs'
import { graphql } from 'gatsby'
import LazyLoad from 'react-lazyload'
import ResourceCard from '../components/resource_card'

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.onTagClick = this.onTagClick.bind(this)
    this.state = {
      activeTags: []
    }
  }

  // Active tags are removed and inactive tags are added from state
  onTagClick(tagName) {
    let activeTags
    if (this.state.activeTags.includes(tagName)) {
      activeTags = this.state.activeTags.filter((t) => t !== tagName) // Remove tag
    } else {
      activeTags = this.state.activeTags.concat(tagName) // Add tag
    }
    this.setState({ activeTags })
  }

  // componentDidMount applies tags from the URL (on a direct load)
  componentDidMount() {
    const currentFilter = qs.parse(window.location.search, { arrayFormat: 'brackets', ignoreQueryPrefix: true })
    if (currentFilter.tags) {
      this.setState({ activeTags: currentFilter.tags })
    }
  }

  // componentDidUpdate applies filters when the URL's parameters change.
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.activeTags !== this.state.activeTags) {
      const tagParams = qs.stringify({ tags: this.state.activeTags }, { arrayFormat: 'brackets', addQueryPrefix: true })
      if (tagParams !== '') {
        window.history.replaceState(null, '', tagParams)
      } else {
        window.history.replaceState(null, '', window.location.pathname)
      }
    }
  }

  render() {
    const tagNames = this.props.data.allMarkdownRemark.nodes
      .map(resource => resource.frontmatter.tags)
      .flat()
      .filter((tag, index, self) => self.indexOf(tag) === index && typeof (tag) === 'string')
      .sort()

    const sidenav = (
      <Sidenav>
        <div><TopPosts/></div>
        <div><RecentPosts/></div>
        <div><TagList
          onTagClick={this.onTagClick}
          activeTags={this.state.activeTags}
          tagNames={tagNames}
        /></div>
      </Sidenav>
    )

    const resourceCards = this.props.data.allMarkdownRemark.nodes.map((resource) =>
      <LazyLoad
        key={`${resource.id}-lazy_load`}
        height={300}
        offset={800}
        once={true}>
        <ResourceCard
          key={resource.id}

          category={resource.frontmatter.category}
          imageURL={resource.frontmatter.imageURL}

          title={resource.frontmatter.title}
          subtext={resource.frontmatter.subtitle || resource.excerpt}
          author={resource.frontmatter.author}
          date={resource.frontmatter.date}

          actionText="READ MORE"
          actionTarget={resource.fields.slug}

          tagNames={resource.frontmatter.tags}
          onTagClick={this.onTagClick}
          activeTags={this.state.activeTags}
        />
      </LazyLoad>
    )


    return (
      <Base subject="Resources">
        <Hero
          // tagline="Resources"
          // heroImage={ResourcesHeroImg}
          heroHeight='30vh'
        />
        <Content sidenav={sidenav}>{resourceCards}</Content>
      </Base>
    )
  }
}


export const data = graphql`
    {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: {fields:{slug:{regex:"^\/resources\/"}}}
        ){
            nodes {
                id
                fields {
                    slug
                }
                frontmatter {
                    title
                    subtitle
                    date(formatString:"YYYY-MM-DD")
                    author
                    imageURL
                    category
                    tags
                }
                excerpt(pruneLength: 75)
            }
        }
    }`

export default IndexPage