import React from "react"
import Tag from "../../tag"

const TagList = (props) => {
  const tags = props.tagNames.map((tag, index) => {
    const active = props.activeTags.includes(tag)
    return (
        <Tag key={`sidenav-tag-${tag}`} name={tag} onTagClick={props.onTagClick} active={active} />
    )
  })

  return (
    <div>
      <h3>Tags</h3>
      {tags}
    </div>
  )
}

export default TagList