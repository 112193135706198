import React from "react"
import styles from "./tag.module.scss"

const Tag = (props) => {
  // name: string
  // onTagClick: any
  // active: boolean

  let className = styles.tag
  if (props.active) {
    className = `${styles.tag} ${styles.tagActive}`
  }

  return (
    <button className={className}
            onClick={(e) => props.onTagClick(props.name)}
    >{props.name}</button>
  )
}

export default Tag