// @ts-ignore
import React from "react"
// @ts-ignore
import styles from "./resource_card.module.scss"
// @ts-ignore
import categoryStyles from "./categories.module.scss"
import { Link } from "gatsby"
import Tag from "../tag/index"

class ResourceCard extends React.Component<any, any> {
  static defaultProps = {
    actionText: "READ MORE",
    category: ""
  }
  props: {
    category: string // The category of resource

    imageURL: string // The URL to the resource card image

    tagNames?: [string] // A list of tags relevant to the resource
    onTagClick: (string) // A callback to use when a tag is clicked
    activeTags: [string] // A list of active tags (passed down from resources)

    title: string // The resource title
    subtext?: string // The resource subtext (an excerpt is used when not provided)

    author: string // The resource author
    date: string // The last update date of the resource

    actionText?: string // The text of the resource CTA (e.g., "READ MORE")
    actionTarget: string // The resource location
  }

  render() {
    // Ensure an empty array is used for posts without tags
    const activeTags = this.props.activeTags || []
    const tagNames = this.props.tagNames || []

    // Format the overall card style

    // Manage the category gradient
    let categoryStyle
    switch (this.props.category) {
      case "signal":
        categoryStyle = categoryStyles.categorySignal
        break
      default:
        categoryStyle = categoryStyles.default
    }

    // Manage visibility based on tag logic (only show posts with ALL active tags)

    let currentStyle = ""
    if (this.props.activeTags.length > 0) {
      // @ts-ignore (the parser incorrectly assumes tagNames is an empty array)
      const containsAllTags = activeTags.every(t => tagNames.includes(t))
      currentStyle = containsAllTags ? "" : styles.cardHidden
    }

    // Return the card jsx
    return (
      <div className={currentStyle}>
        <div className={styles.card}>

          <div className={[styles.card__category, categoryStyle].join(" ")}/>


          <Link
            to={`/${this.props.actionTarget}`}
            className={styles.card__image}
            style={{
              background: `#ffffff url("${this.props.imageURL}")`,
              backgroundSize: 'cover',
            }}
          />

          <div className={styles.card__details}>
            <div className={styles.card__tags}>
              {this.props.tagNames && this.props.tagNames.map((tagName) =>
                <Tag
                  key={tagName}
                  name={tagName}
                  active={this.props.activeTags.includes(tagName)}
                  onTagClick={this.props.onTagClick}
                />
              )}
            </div>

            <Link to={`/${this.props.actionTarget}`} className={styles.card__teaser}>
              <h3>{this.props.title}</h3>
              <p>{this.props.subtext}</p>
            </Link>

            <div className={styles.card__divider}>
              <hr/>
            </div>
            <div className={styles.card__author}>{this.props.author}</div>
            <div className={styles.card__date}>{this.props.date}</div>

            <div className={styles.card__action}>
              <Link to={`/${this.props.actionTarget}`}>{this.props.actionText}</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ResourceCard